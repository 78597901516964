.container {
  padding-top: 0 !important;
  margin-top: 10px;
}

h1 {
  margin-top: 15px !important;
  margin-bottom: 30px !important;
  text-align: center;
}

h2 {
  margin-bottom: 12px !important;
}

/* Titelbild Styling */
.titelbild-container {
  display: flex;
  justify-content: center;
  align-items: center;
  
  margin-bottom: 20px;
  width: 100%;
  height: 40vh; /* Höhe von 60% des Bildschirms */
}

.titelbild {
  width: 100%;
  height: auto;
  max-height: 100%; /* Begrenze die Höhe auf 100% des Containers */
  object-fit: contain; /* Behalte die Seitenverhältnisse und fülle den Container aus */
  border: 4px solid #fff; /* Border mit 4px Breite */
  border-radius: 10px; /* Abgerundete Ecken */
}

/* Darkmode Styles */
body.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.card.dark-mode {
  background-color: #1e1e1e;
  color: #ffffff;
}

.btn-primary.dark-mode {
  background-color: #3f51b5;
  border-color: #3f51b5;
}

.btn-primary.dark-mode:hover {
  background-color: #2c387e;
  border-color: #2c387e;
}

.card-title {
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 12px !important;
}

.card-button {
  margin-top: 10px;
}

.video-container {
  position: relative;
  padding-top: 56.25%;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dark-mode-toggle-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  gap: 10px;
}

.dark-mode-toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.dark-mode-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.titelbild {
    width: auto;
    height: 100%;
    max-height: none; /* Keine Begrenzung der Höhe auf mobilen Geräten */
    object-fit: cover; /* Fülle den Container aus und schneide bei Bedarf ab */
}


@media (max-width: 768px) {
  .titelbild-container {
    width: 80vw; /* Reduziere die Höhe auf mobilen Geräten */
    max-height: 30vh;
  }
  .titelbild {
    width: auto;
    max-height: none; /* Keine Begrenzung der Höhe auf mobilen Geräten */
    object-fit: contain; /* Fülle den Container aus und schneide bei Bedarf ab */
}
}