/* src/components/Navbar.css */

.navbar-nav .nav-item .nav-link:hover {
  color: #fff; /* Farbe bei Hover */
}

.hover-dropdown:hover > .dropdown-menu {
  display: block;
  opacity: 1;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  visibility: visible;
}

.hover-dropdown .dropdown-menu {
  opacity: 0;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  visibility: hidden;
}

.navbar-nav {
  flex-direction: row;
}

.navbar-nav .nav-item {
  margin-left: 20px; /* Optional: Abstand zwischen den Items */
}
