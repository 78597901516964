.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Vollbildhöhe */
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    display: flex;
    flex-direction: column; /* Vertikale Ausrichtung */
    align-items: center;
    justify-content: center;
    padding: 40px;
    z-index: 1000;
    text-align: center; /* Text zentrieren */
  }
  
  .cookie-image {
    width: auto;
    height: 70vh; /* Höhe des Bildes auf 70% der Bildschirmhöhe setzen */
    object-fit: contain; /* Verhindert das Verzerren des Bildes */
    margin-bottom: 20px; /* Abstand zwischen Bild und Text */
  }
  
  .cookie-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .cookie-message p {
    margin: 0;
    margin-bottom: 20px; /* Abstand unter dem Text */
    font-size: 1.5rem; /* Vergrößere die Schriftgröße */
  }
  
  .cookie-message button {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 15px 30px; /* Vergrößere die Knopfgröße */
    font-size: 1.2rem; /* Vergrößere die Schriftgröße des Knopfs */
    cursor: pointer;
  }
  
  .cookie-message button:hover {
    background-color: #0056b3;
  }
  