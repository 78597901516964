.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #343a40; /* Gleiche Farbe wie Navbar */
    color: white;
    text-align: center;
    padding: 2px 0; /* Reduziertes Padding */
    font-size: 0.8rem; /* Kleinere Schriftgröße */
  }
  
  .footer .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  