/* src/components/InfiniteImageGallery.css */

.image-grid {
  display: grid;
  grid-gap: 10px;
  margin: 0 auto;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Standardisierte Höhe für die Bilder */
  overflow: hidden;
  border: 2px solid #ccc;
  border-radius: 5px;
}

.responsive-image {
  width: auto;
  height: 100%; /* Bildhöhe auf 100% setzen, damit es die gesamte Höhe ausfüllt */
  object-fit: cover; /* Bild passend zuschneiden */
  object-position: center; /* Bild zentrieren */
  cursor: pointer;
}

.load-more-container {
  text-align: center;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .image-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 767px) {
  .image-wrapper {
    height: auto; /* Höhe an den Inhalt anpassen */
  }

  .responsive-image {
    height: auto;
    max-height: 97vh;
    max-width: 97%;
  }
}
